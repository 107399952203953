import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useBus } from '@/shared/composables'
import { eventTypes } from '@/shared/constants/bus-events-constants';

export function usePChat() {

  const route = useRoute()
  const { busOn, busOff } = useBus()

  const selectedConversation = ref(null)
  const isChatAnswering = ref(false)

  const isSidebarPinned = ref(false)
  const isShowSidebar = ref(false)
  const gradientOpacity = ref(0)
  const hideSidebarIntervalId = ref(null)
  const mouseX = ref(300)
  const showSidebar = computed(() => route.query.showSidebar !== 'false' && isShowSidebar.value)

  const mainClassOptions = computed(() => {
    return {
      'p-chat__main--hidden-sidebar': !isSidebarPinned.value,
    }
  })

  const onChangeConversation = (item) => {
    selectedConversation.value = item
  }

  function handleMouseMove(event) {
    mouseX.value = event.clientX;
    // Calculate how close the mouse is to the left side (100px range)
    if (mouseX.value < 100) {
      gradientOpacity.value = (100 - mouseX.value) / 100;
      if (mouseX.value <= 10) {
        isShowSidebar.value = true;
      }
    } else if (mouseX.value > 280) {
      if (isShowSidebar.value && !hideSidebarIntervalId.value && !isSidebarPinned.value) {
        hideSidebarIntervalId.value = setTimeout(() => {
          isShowSidebar.value = false;
          hideSidebarIntervalId.value = null
        }, 500)
      } else if (!isShowSidebar.value && gradientOpacity.value !== 0) {
        gradientOpacity.value = 0;
      }
    }
  }

  function pinSidebar() {
    isShowSidebar.value = true
    isSidebarPinned.value = true
  }
  function unpinSidebar() {
    isShowSidebar.value = false
    isSidebarPinned.value = false
  }

  function changeChatAnswering(value) {
    isChatAnswering.value = value
  }

  function initialization() {
    busOn(eventTypes.SIDEBAR.SHOW, pinSidebar)

  }
  function deInitialization() {
    busOff(eventTypes.SIDEBAR.SHOW, pinSidebar)
  }

  return {
    gradientOpacity,
    isSidebarPinned,
    showSidebar,
    isChatAnswering,
    selectedConversation,
    mainClassOptions,
    pinSidebar,
    unpinSidebar,
    changeChatAnswering,
    handleMouseMove,
    initialization,
    deInitialization,
    onChangeConversation,
  }
}