<template>
  <div
    class="a-conversation-item"
    :class="classOptions"
    @mouseenter="isConversationHovered = true"
    @mouseleave="isConversationHovered = false"
    @click="onClick"
  >
    <p>
      <slot />
    </p>
    <div class="a-conversation-item__options">
      <OMenu
        v-if="isConversationHovered || isMenuHovered"
        :items="CONVERSATION_MENU_OPTIONS"
        :nudge-bottom="36"
        :nudge-left="105"
        :max-width="140"
        :is-disabled="isDisabled"
        @toggleMenu="isMenuHovered = $event"
        @mouseenter="isMenuHovered = true"
        @mouseleave="isMenuHovered = false"
        @selected="handleConversationAction($event)"
      >
        <DButton
          icon-name="overflow-menu-vertical"
          type="tertiary"
          size="sm"
        />
      </OMenu>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed, ref } from 'vue'
import { useRoute } from 'vue-router';

import DButton from '@/shared/components/d-button'
import OMenu from '@/shared/components/o-menu'

import { CONVERSATION_MENU_OPTIONS, CONVERSATION_MENU_VALUE } from './a-conversation-item-constants';

const emit = defineEmits(['click'])
const props = defineProps({
  item: {
    type: Object,
    default: () => {
      return {}
    },
  },
  isDisabled: {
    type: Boolean,
    default: () => false,
  },
})
const route = useRoute()

const isConversationHovered = ref(false)
const isMenuHovered = ref(false)

const routeConversationUuid = computed(() => route.query.conversationUuid)
const classOptions = computed(() => {
  return {
    'a-conversation-item--selected': routeConversationUuid.value === props.item.uuid,
    'a-conversation-item--disabled': props.isDisabled,
  }
})

const onClick = () => {
  if (!props.isDisabled) {
    emit('click')
  }
}

function onClickDeleteConversation() {
  emit('onClickDeleteConversation', props.item)
}

function handleConversationAction(event) {
  if (event === CONVERSATION_MENU_VALUE.DELETE) {
    onClickDeleteConversation()
  }
}

</script>

<style lang="scss">
@import "a-conversation-item.scss";
</style>