<template>
  <div
    v-if="isShowSuggestions"
    class="o-message__header-questions"
  >
    <DButton
      v-for="(question, index) in message.questions"
      :key="index"
      type="tertiary"
      :text="question"
      :width-type="D_BUTTON_WIDTH_TYPE.FIT_CONTENT"
      @click="onSetMessage(question)"
    />
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import { AButtonMessage } from '../a-button-message';
import DButton from '@/shared/components/d-button'

import { D_BUTTON_WIDTH_TYPE } from '../../shared/components/d-button/d-button-constants';

const props = defineProps({
  message: {
    type: Object,
    default: () => ({})
  },
});
const emit = defineEmits(['onSetMessage']);

const isShowSuggestions = computed(() => {
  return props.message.questions && props.message.questions.length;
})

function onSetMessage(question) {
  emit('onSetMessage', question);
}
</script>