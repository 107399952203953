import backendClient from './backend-api-client'

const ENDPOINTS = {
  GET_PROJECT: ({ descriptor_base_path, apiKeyUuid }) => `/project?descriptor_base_path=${descriptor_base_path}&apiKeyUuid=${apiKeyUuid}`,
  POST_PROJECT: ({ apiKeyUuid }) => `/project?apiKeyUuid=${apiKeyUuid}`,
  POST_PROJECT_CACHE_RESET: ({ apiKeyUuid, descriptor_base_path }) => `/project/datasets/cache/reset?descriptor_base_path=${descriptor_base_path}&apiKeyUuid=${apiKeyUuid}`,
  GET_CHARTS: ({ apiKeyUuid }) => `/project/exploration/generate-charts?apiKeyUuid=${apiKeyUuid}`,
  GET_TABLES: ({ apiKeyUuid }) => `/project/exploration/generate-tables?apiKeyUuid=${apiKeyUuid}`,
  GET_CONVERSATIONS: ({ apiKeyUuid }) => `/project/exploration/conversations/list?apiKeyUuid=${apiKeyUuid}`,
  GET_CONVERSATION: ({ apiKeyUuid }) => `/project/exploration/conversation/item?apiKeyUuid=${apiKeyUuid}`,
  ARCHIVE_CONVERSATION: ({ apiKeyUuid }) => `/project/exploration/conversations/archive?apiKeyUuid=${apiKeyUuid}`,
  CREATE_CONVERSATION: ({ apiKeyUuid }) => `/project/exploration/conversation/create?apiKeyUuid=${apiKeyUuid}`,
  STREAM_CONVERSATION: ({ apiKeyUuid }) => `/project/exploration/conversation/stream?apiKeyUuid=${apiKeyUuid}`,
  CANCEL_TASK: ({ task_id, apiKeyUuid }) => `/project/exploration/conversation/stream/stop?task_id=${task_id}&apiKeyUuid=${apiKeyUuid}`,
  FEEDBACK: ({ apiKeyUuid }) => `/project/exploration/feedback?apiKeyUuid=${apiKeyUuid}`,
}

export const feedback = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.FEEDBACK(query), payload)
)
export const getCharts = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.GET_CHARTS(query), payload)
)
export const getTables = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.GET_TABLES(query), payload)
)
export const createConversation = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.CREATE_CONVERSATION(query), payload)
)
export const getConversations = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.GET_CONVERSATIONS(query), payload)
)
export const archiveConversation = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.ARCHIVE_CONVERSATION(query), payload)
)
export const getConversation = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.GET_CONVERSATION(query), payload)
)
export const getProject = ({ query }) => (
  backendClient.get(ENDPOINTS.GET_PROJECT(query))
)
export const initProject = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.POST_PROJECT(query), payload)
)
export const resetCacheProject = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.POST_PROJECT_CACHE_RESET(query), payload)
)
export const cancelTask = ({ payload, query }) => (
  backendClient.post(ENDPOINTS.CANCEL_TASK(query), payload)
)
