import { getConversations, archiveConversation } from '../api/conversation.api'

const conversations = {
  namespaced: true,
  state: () => ({
    conversations: [],
    currentConversation: null,
  }),
  mutations: {
    SET_CONVERSATIONS(state, conversations) {
      state.conversations = conversations
    },
    SET_CURRENT_CONVERSATION(state, conversation) {
      state.currentConversation = conversation
    },
    ARCHIVE_CONVERSATION(state, conversationUuid) {
      const targetUuid = state.conversations.find(conversation => conversation.uuid === conversationUuid)
      if (targetUuid) {
        state.conversations = state.conversations.filter(conversation => conversation.uuid !== conversationUuid)
        if (state.conversations.length === 0) {
          state.currentConversation = null
        }
      }
    },
  },
  actions: {
    async getConversations({ commit, rootGetters }, { descriptorBasePath, apiKeyUuid, userUuid } = {}) {
      let options = {}
      if (descriptorBasePath) {
        options.descriptor_base_path = descriptorBasePath
      }
      const userUuidLocal = rootGetters['user/getUserUuid']
      const { data } = await getConversations({
        payload: {
          user_uuid: userUuid || userUuidLocal,
          ...options,
        },
        query: {
          apiKeyUuid,
        }
      })
      commit('SET_CONVERSATIONS', data)
      return data
    },
    async archiveConversation({ commit, rootGetters }, { apiKeyUuid, ...payload } = {}) {
      const { data } = await archiveConversation({
        payload,
        query: {
          apiKeyUuid,
        }
      })
      commit('ARCHIVE_CONVERSATION', payload.uuid)
      return data
    }
  },
  getters: {
    getConversations: (state) => {
      return state.conversations || []
    },
  },
}

export default conversations
