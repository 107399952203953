export const CONVERSATION_MENU_VALUE = {
  DELETE: 'delete',
}

export const CONVERSATION_MENU_OPTIONS = [
  {
    title: 'Delete',
    value: CONVERSATION_MENU_VALUE.DELETE,
  }
]
