export const D_BUTTON_TYPE = {
  DEFAULT: 'default',
  OUTLINED: 'outlined',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  FLAT: 'flat',
  EMPTY: 'empty',
}
export const D_BUTTON_SIZE = {
  XSM: 'xsm',
  SM: 'sm',
  MD: 'md',
}
export const THEME_OPTIONS = [D_BUTTON_TYPE.DEFAULT, D_BUTTON_TYPE.OUTLINED]

export const D_BUTTON_WIDTH_TYPE = {
  FULL_WIDTH: 'full-width',
  FIT_CONTENT: 'fit-content',
}
