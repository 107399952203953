export const eventTypes = {
  'SIDEBAR': {
    'SHOW': 'SIDEBAR_SHOW',
    'NEW_CHAT': 'SIDEBAR_NEW_CHAT',
  },

  AI_ANALYST_REFRESH_IFRAME: 'AI_ANALYST_REFRESH_IFRAME',
  AI_ANALYST_DISABLE_IFRAME: 'AI_ANALYST_DISABLE_IFRAME',
  AI_ANALYST_ENABLE_IFRAME: 'AI_ANALYST_ENABLE_IFRAME',
  AI_ANALYST_STARTED_IFRAME: 'AI_ANALYST_STARTED_IFRAME',
  AI_ANALYST_STOPPED_IFRAME: 'AI_ANALYST_STOPPED_IFRAME',
  AI_ANALYST_SCHEMA_CHANGED: 'AI_ANALYST_SCHEMA_CHANGED',

  // FROM PARENT
  AI_ANALYST_SCHEMA_CHANGED_FINISH: 'AI_ANALYST_SCHEMA_CHANGED_FINISH',
  AI_ANALYST_SCHEMA_CHANGED_ERROR: 'AI_ANALYST_SCHEMA_CHANGED_ERROR',

  // MIGRATION
  AI_ANALYST_SET_USER_UUID: 'AI_ANALYST_SET_USER_UUID',
}

export const HIDE_MODAL = 'close'
export const CHECK_ACTIVATED = 'checkActivated'
export const LINK_ASSET_CREATED = 'linkAssetCreated'
export const EVENT_CREATED_NEW_DATASET = 'EVENT_CREATED_NEW_DATASET'

export const DATA_PREVIEW_SELECT = 'dataPreviewSelect'

// HELP EVENT
export const HELP_EVENT_RUN = 'helpEventRun'
export const HELP_EVENT_SELECT_BRICK = 'helpEventSelectBrick'
export const HELP_EVENT_OPEN_DASHBOARD = 'helpEventOpenDashBoard'
export const HELP_EVENT_CLOSE_DASHBOARD = 'helpEventCloseDashBoard'
export const HELP_EVENT_TRIGGER = 'helpEventTrigger'
export const HELP_CLOSE = 'helpClose'
export const HELP_EVENT_SHOW_HELP_POINTER = 'showHelpPointer'
export const HELP_EVENT_SHOW_HELP_BRICK = 'showHelpBrick'
export const HELP_EVENT_OPEN_MODULE_MODEL_INFO = 'openModuleModelInfo'
export const HELP_EVENT_OPEN_DATA_PREVIEW = 'openDataPreview'
export const HELP_EVENT_CONNECT_BRICK = 'connectBrick'
export const HELP_EVENT_SELECT_SOME_BRICK = 'helpEventSelectSomeBrick'
export const HELP_EVENT_SHOW_BRICKS = 'helpEventShowBricks'

export const EVENT_SAVE_DB_DATASET = 'save-db-dataset'
export const EVENT_GET_PREVIEW = 'getPreview'
export const EVENT_GET_DB_PREVIEW = 'get-db-preview'

// GENERAL EVENT
export const EVENT_INPUT = 'input'
export const EVENT_CLOSE = 'close'
export const EVENT_CLOSED = 'closed'
export const EVENT_COPY = 'copy'
export const EVENT_CLICK = 'click'
export const EVENT_CLICKED = 'clicked'
export const EVENT_SAVED = 'saved'
export const EVENT_CHANGE = 'change'
export const EVENT_SELECT = 'select'
export const EVENT_DELETE = 'delete'
export const EVENT_BLUR = 'blur'
export const EVENT_RESET = 'reset'
export const EVENT_UPDATE = 'update'
export const EVENT_CHANGED = 'changed'
export const EVENT_GO_HOME = 'goHome'
export const EVENT_GO_BACK = 'goBack'
export const EVENT_GO_NEXT = 'goNext'
export const EVENT_FILTERED = 'filtered'
export const EVENT_SELECTED = 'selected'
export const EVENT_ON_DELETE = 'onDelete'

// PIPELINE BRICK LINKING
export const EVENT_LINKING_BREAK = 'linkingBreak'
export const EVENT_LINKING_STOP = 'linkingStop'
export const EVENT_LINKING_START = 'linkingStart'

// PIPELINE CONTROL PANEL
export const EVENT_SHOW_COMMENTS_LIST = 'showCommentsList'
export const EVENT_DECREASE_GRID_SIZE = 'decreaseGridSize'
export const EVENT_INCREASE_GRID_SIZE = 'increaseGridSize'
export const EVENT_SWITCH_GRID_DISPLAY = 'switchGridDisplay'
export const EVENT_MOVE_SCENE_TO_CENTER = 'moveSceneToCenter'

// PIPELINE NAVIGATION
export const EVENT_GO_TO_BRICK_PIPELINE = 'goToBrickPipeline'

// PIPELINE COMMENT
export const EVENT_HANDLE_COMMENT = 'handleComment'

export const EVENT_DELETE_ARGUMENT = 'deleteArgument'
export const EVENT_UPDATE_CONNECTION = 'updateConnection'
export const EVENT_DELETE_CONNECTION = 'deleteConnection'
export const EVENT_GROUP_NAME_INPUT = 'groupNameInput'
export const EVENT_GROUP_FRAME_MOVE = 'groupFrameMove'
export const EVENT_GROUP_FRAME_STOP = 'groupFrameStop'
export const EVENT_SELECT_BRICKS = 'selectBricks'
export const EVENT_TOGGLE_MENU = 'toggle-menu'
export const EVENT_TAB_CHANGE = 'tabChange'
export const EVENT_CHANGE_STEP = 'changeStep'
export const EVENT_CONFIG_CHANGED = 'configChanged'
export const EVENT_PREVIEWED = 'previewed'
export const EVENT_FILE_LOADING = 'fileLoading'
export const EVENT_FILE_LOADED = 'fileLoaded'
export const EVENT_FILE_SAVED = 'fileSaved'
export const EVENT_FILE_SAVED_FAILURE = 'fileSavedFailure'
export const EVENT_SELECT_FULL_EXCEL = 'selectFullExcel'
export const EVENT_DATA_SOURCE_SELECTED = 'dataSourceSelected'
export const EVENT_SHOW_DATASET_CREATE = 'showDatasetCreate'
export const EVENT_CHANGE_DB_PREVIEW_TYPE = 'change-db-preview-type'
export const EVENT_DELETE_BRICK_SLOT = 'delete-brick-slot'
export const EVENT_DESELECT_GROUP_ALL = 'deselectGroupAll'
export const EVENT_REMOVE_NOT_GROUP_SELECTION = 'removeNotGroupSelection'
export const EVENT_HANDLE_CHECK_COLUMN = 'handle-check-column'
export const EVENT_HANDLE_SELECT_COLUMN = 'handle-select-column'
export const EVENT_HANDLE_ACTION_AUTO = 'handle-action-auto'
export const EVENT_HANDLE_ACTION_RESET = 'handle-action-reset'
export const EVENT_UPDATE_PROPERTIES = 'update-properties'
export const EVENT_UPDATE_INPUTS = 'updateInputs'
export const EVENT_UPDATE_OUTPUTS = 'updateOutputs'
export const EVENT_UPDATE_NAME = 'updateName'
export const EVENT_UPGRADE_BRICK = 'upgradeBrick'
export const EVENT_COMPLETED = 'completed'
export const EVENT_SAVE_BRICK = 'saveBrick'
export const EVENT_UPDATE_CODE = 'updateCode'
export const EVENT_UPDATE_ARGUMENT = 'updateArgument'
export const EVENT_UPDATE_ARGUMENTS = 'updateArguments'
export const EVENT_VALIDATE_CODE = 'validateCode'
export const EVENT_BLUR_SNACKBAR = 'blurSnackbar'
export const EVENT_FOCUS_SNACKBAR = 'focusSnackbar'
export const EVENT_COMPLETED_STEP = 'completedStep'
export const EVENT_SAVE_DATA_SET = 'saveDataSet'
export const EVENT_SAVE_RULES = 'saveRules'
export const EVENT_ADD_VALUE_TO_INPUT = 'add-value-to-input'
export const EVENT_UPLOAD_FILE_PROGRESS = 'uploadFileProgress'
export const EVENT_HANDLE_ACTION_CALCULATE = 'handle-action-calculate'
export const EVENT_CHECK_COLUMN = 'check-column'
export const EVENT_MINIMIZE = 'minimize'
export const EVENT_MINIMALIZE = 'minimalize'
export const EVENT_ROW_ACTION = 'rowAction'
export const EVENT_SORT_CHANGE = 'sortChange'
export const EVENT_PAGE_CHANGE = 'pageChange'
export const EVENT_TYPE_CHANGE = 'typeChange'
export const EVENT_CELL_COPY = 'copyCell'
export const EVENT_CELL_SELECT = 'selectCell'
export const EVENT_CELL_PREVIEW = 'previewCell'
export const EVENT_SELECTED_SUB = 'selectedSub'
export const EVENT_UPDATE_TITLE = 'updateTitle'
export const EVENT_RUN_PIPELINE = 'runPipeline'
export const EVENT_STOP_PIPELINE = 'stopPipeline'
export const EVENT_ON_CHANGE_TAB = 'onChangeTab'
export const EVENT_ON_ADD_SECTION = 'on-add-section'
export const EVENT_ON_CHANGE_MODEL = 'on-change-model'
export const EVENT_CLOSE_DB_CONNECT = 'closeDatasourceConnect'
export const EVENT_ON_REMOVE_SECTION = 'on-remove-section'
export const EVENT_SWITCH_BRICKS_GRID_BINDING = 'switchBricksGridBinding'
export const EVENT_TEST_DATA_SOURCE_CONNECTION_CLICKED = 'testDataSourceConnectionClicked'
export const EVENT_TEST_MUTATE_PROJECT_DATA_SOURCE_CLICKED = 'testMutateProjectDataSourceClicked'
export const EVENT_BRICK_PIPELINE_PATH_UPDATED = 'EVENT_BRICK_PIPELINE_PATH_UPDATED'
export const EVENT_SELECT_ITEM = 'EVENT_SELECT_ITEM'
export const EVENT_SELECT_SUB_ITEM = 'EVENT_SELECT_SUB_ITEM'


