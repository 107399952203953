export const DUI_KEY = {
  'DUI-GREY-100': 'DUI-GREY-100',
  'DUI-GREY-200': 'DUI-GREY-200',
  'DUI-GREY-300': 'DUI-GREY-300',
  'DUI-GREY-400': 'DUI-GREY-400',
  'DUI-GREY-500': 'DUI-GREY-500',
  'DUI-GREY-600': 'DUI-GREY-600',
  'DUI-GREY-700': 'DUI-GREY-700',
  'DUI-GREY-800': 'DUI-GREY-800',
  'DUI-GREY-900': 'DUI-GREY-900',
}

export const DUI_COLORS = {
  // GREY
  [DUI_KEY["DUI-GREY-100"]]: '#D8E4EB',
  [DUI_KEY["DUI-GREY-200"]]: '#C6D5E0',
  [DUI_KEY["DUI-GREY-300"]]: '#A8BBCC',
  [DUI_KEY["DUI-GREY-400"]]: '#8B9BAC',
  [DUI_KEY["DUI-GREY-500"]]: '#768A9E',
  [DUI_KEY["DUI-GREY-600"]]: '#4F6984',
  [DUI_KEY["DUI-GREY-700"]]: '#395774',
  [DUI_KEY["DUI-GREY-800"]]: '#234465',
  [DUI_KEY["DUI-GREY-900"]]: '#152A40',
}