<template>
  <Icon
    :icon="`${label}:${name}`"
    class="a-iconify"
    :style="styleOptions"
    :class="iconClassOptions"
  />
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  defineProps,
} from 'vue'

import { Icon } from '@iconify/vue';
import { DUI_KEY } from '../../constants/dui-colors';

const props = defineProps({
  label: {
    type: String,
    default: 'carbon',
  },
  /**
   * Icon name from https://icon-sets.iconify.design/carbon/?query=side
   */
  name: {
    type: String,
    validator: val => !val.match(/[.]/g),
    default: '',
  },
  /**
   * Element colors
   */
  color: {
    type: String,
    default: DUI_KEY['DUI-GREY-500'],
  },
  /**
   * Element font size
   *
   * by default it will set size points in 'px'
   * example: 20 or '20'
   */
  size: {
    type: String,
    default: '16',
  },
})

const styleOptions = computed(() => {
  return {
    'fontSize': `${props.size}px`,
  }
})

const iconClassOptions = computed(() => {
  return {
    [`color-${props.color}`]: true,
  }
})

</script>
