import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

export const formatDateToTimeAgo = isoDateString => {
  TimeAgo.addLocale(en)
  const timeAgo = new TimeAgo('en-US')
  return timeAgo.format(new Date(isoDateString), 'round')
}

export function formatISOStringDate(isoString) {
  const date = new Date(isoString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Manually format the string in the desired format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
}

export const toLocaleDate = isoDateString => {
  return new Date(isoDateString).toLocaleDateString()
}

export const toLocaleDateWithTime = isoDateString => {
  const date = new Date(isoDateString)
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

export const toLocaleDateWithTimeNoSec = isoDateString => {
  const date = new Date(isoDateString)
  return `${date.toLocaleDateString(
    undefined, {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    },
  )} ${date.toLocaleTimeString(
    undefined,
    {
      hour: '2-digit',
      minute: '2-digit',
    },
  )}`
}
