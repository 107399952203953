<template>
  <div
    class="d-chat-input"
    @mouseenter="isInputHovered = true"
    @mouseleave="isInputHovered = false"
  >
    <DTextArea
      v-model="inputValue"
      :disabled="isInputDisabled"
      :is-auto-rows="true"
      :max-rows="5"
      :is-hovered-from-parent="isInputHovered"
      placeholder="Start typing your question here..."
      padding="12px 80px 12px 12px"
      @onEnter="emit('onClickSend')"
    />
    <div class="d-chat-input__send-container">
      <ATooltip
        v-if="isShowStop"
        content="Stop response"
      >
        <DButton
          icon-name="stop"
          type="flat"
          @click="emit('handleCancelTask')"
        />
      </ATooltip>
      <DButton
        v-else
        text="Send"
        type="secondary"
        :is-disabled="isSendDisabled"
        :is-hidden-disabled="isSendHiddenDisabled"
        @click="emit('onClickSend')"
        />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted, onBeforeMount } from 'vue';

import DTextArea from '@/shared/components/d-textarea';
import DButton from '@/shared/components/d-button'
import ATooltip from '@/shared/components/a-tooltip'

const emit = defineEmits(['update:modelValue', 'onClickSend', 'handleCancelTask'])
const props = defineProps({
  modelValue: {
    type: String,
    default: () => '',
  },
  isShowStop: {
    type: Boolean,
    default: () => false,
  },
  isInputDisabled: {
    type: Boolean,
    default: () => false,
  },
  isSendDisabled: {
    type: Boolean,
    default: () => false,
  },
  // Looks like default button, but dont give possibility to press button and send actions
  isSendHiddenDisabled: {
    type: Boolean,
    default: () => false,
  },
})

const isInputHovered = ref(false)

const inputValue = computed({
  get: () => {
    return props.modelValue},
  set: (value) => emit('update:modelValue', value),
})
</script>

<style lang="scss">
@import "d-chat-input.scss";
</style>