<template>
  <div class="container-body"  @mousemove="handleMouseMove">
    <div
      v-if="!showSidebar"
      class="gradient-overlay"
      :style="{ opacity: gradientOpacity }"
    >
    </div>
    <transition name="fadeInRight">
      <OSidebar
        v-show="showSidebar"
        :is-sidebar-pinned="isSidebarPinned"
        :is-chat-answering="isChatAnswering"
        @onChangeConversation="onChangeConversation"
        @onClickPinSidebar="pinSidebar"
        @onClickUnpinSidebar="unpinSidebar"
      />
    </transition>

    <div
      class="p-chat__main"
      :class="mainClassOptions"
    >
      <OHeader
        :is-sidebar-pinned="isSidebarPinned"
      />
      <OChatBody
        :conversation="selectedConversation"
        :is-sidebar-pinned="isSidebarPinned"
        :is-chat-answering="isChatAnswering"
        @changeChatAnswering="changeChatAnswering"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';

import OSidebar from '../../components/o-sidebar'
import OChatBody from '../../components/o-body'
import OHeader from '@/components/o-header'
import { usePChat } from './use-p-chat';

const {
  gradientOpacity,
  showSidebar,
  isSidebarPinned,
  selectedConversation,
  mainClassOptions,
  isChatAnswering,
  pinSidebar,
  unpinSidebar,
  changeChatAnswering,
  handleMouseMove,
  initialization,
  deInitialization,
  onChangeConversation,
} = usePChat()

onMounted(() => {
  initialization()
})
onUnmounted(() => {
  deInitialization()
})
</script>

<style lang="scss">
@import "./p-chat.scss";
</style>