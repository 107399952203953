<template>
  <div
    class="d-button"
    :class="buttonClassOptions"
    @click="onClick"
  >
    <!-- :color="color"
    :size="size" -->
    <!-- :label="label" -->
    <AIconify
      v-if="iconName"
      :name="iconName"
    />
    <span
      v-if="text"
      class="d-button__text"
    >
      {{text}}
    </span>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';

import AIconify from '@/shared/components/a-iconify'

import { D_BUTTON_TYPE, D_BUTTON_SIZE, D_BUTTON_WIDTH_TYPE } from './d-button-constants';

const emit = defineEmits(['click'])
const props = defineProps({
  type: {
    type: String,
    default: 'default',
  },
  text: {
    type: String,
    default: '',
  },
  iconName: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: D_BUTTON_SIZE.MD,
  },
  // width 100% or fit content
  widthType: {
    type: String,
    default: D_BUTTON_WIDTH_TYPE.FULL_WIDTH,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  // Looks like default button, but don't give possibility to press button and send actions
  isHiddenDisabled: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const buttonClassOptions = computed(() => {
  return {
    'd-button--disabled': props.isDisabled || props.disabled,
    // button type
    'd-button--outlined': props.type === D_BUTTON_TYPE.OUTLINED,
    'd-button--secondary': props.type === D_BUTTON_TYPE.SECONDARY,
    'd-button--tertiary': props.type === D_BUTTON_TYPE.TERTIARY,
    'd-button--flat': props.type === D_BUTTON_TYPE.FLAT,
    'd-button--empty': props.type === D_BUTTON_TYPE.EMPTY,
    'd-button-size--md': props.size === D_BUTTON_SIZE.MD,
    'd-button-size--sm': props.size === D_BUTTON_SIZE.SM,
    'd-button-size--xsm': props.size === D_BUTTON_SIZE.XSM,
    // width type
    'd-button-width-type--full-width': props.widthType === D_BUTTON_WIDTH_TYPE.FULL_WIDTH,
    'd-button-width-type--fit-content': props.widthType === D_BUTTON_WIDTH_TYPE.FIT_CONTENT,
  }
})

const onClick = () => {
  if (props.isDisabled || props.isHiddenDisabled) return
  emit('click')
}

</script>

<style lang="scss">
@import './d-button.scss';
</style>