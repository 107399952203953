<template>
  <div class="main container">
    <router-view></router-view>
  </div>
</template>

<script setup>
</script>

<style lang="scss">
html {
  overflow: hidden;
}
.container {
  display: flex;
  flex-direction: column;
  flex-basis: content;
  box-sizing: border-box;
  overflow: hidden;
  flex-grow: 0;
}
</style>
